import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormEmail from "../components/form-email"
import { Box, Divider, Flex,  Heading } from "@chakra-ui/core"
import ImagePDF from "../components/imagePDF"

const IndexPage = () => (
  <Layout>
    <SEO
      title="FREE MVP Book"
      description="An MVP is a fundamental part of the Lean Startup Methodology. Learn 10 Secrets that will help you build a successful MVP in this free MVP cheat sheet."
    />
    <Box mx="auto" maxWidth={1400} my={0}>
      <Flex direction={{ base: "column", md: "row" }} justify="center">
        <Box w={{ md: 0.5 }} maxW={{ md: 480 }} mx={4}>
        <ImagePDF mx="auto" maxH={580} alt="MVP Book"/>
        </Box>
        <Box
          mt={8}
          // maxH={500}
          mx={4}
          w={{ md: 0.5 }}
          maxW={{ md: 480 }}
          bg="gray.300"
          borderWidth="4px"
          borderColor="white"
          rounded="lg"
        >
          <FormEmail group="104653765">
            <Heading size="lg" textAlign="center" color="orange.500">
              Read this FREE MVP book
            </Heading>
            <Divider borderColor="gray.400" borderWidth="0px" mx={8} py={3} />
            <Heading size="2xl" textAlign="center" my={4} lineHeight={1.05} color="blue.900" >
              All you need to know about MVPs
            </Heading>
            <Heading size="lg" textAlign="center" my={4} lineHeight={1.05} color="blue.500" >
              (Minimal Viable Products, that is)
            </Heading>
            <Divider borderColor="gray.400" borderWidth="0px" mx={8} py={1} />
            <Heading size="md" fontWeight={700} textAlign="center" my={6} lineHeight={1.35} color="blue.700">
            Want to know the 10 SECRETS we learnt by building PRODUCTS for tech startups?
Then get your FREE digital book below.
            </Heading>
          </FormEmail>
        </Box>
      </Flex>

      <Divider borderColor="gray.400" borderWidth="1px" mx={4} />
    </Box>
  </Layout>
)

export default IndexPage
